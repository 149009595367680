<template>
    <div class="QuizResource">
        
        <div v-if="show === 'quiz' && resource.prompts && resource.prompts.prompts" class="QuizResource__card quiz">
            <h1 class="QuizResource__heading">{{resource.prompts.prompts[0]}}</h1>
            <p class="QuizResource__prompt">{{ prompt }}</p>
            <div class="QuizResource__choices">
                <p class="QuizResource__choice" @click="onSelectChoice(choice.isCorrect, choice.tag)" v-for="choice in choices"
                    :key="choice.text">{{
                        choice.text
                    }}</p>
            </div>
        </div>
        <div v-else-if="show === 'correct' && resource.prompts && resource.prompts.prompts" class="QuizResource__card correct-answer">
            <img :src="resource.correctSticker ? this.$cms.extractImage(resource.correctSticker).url : null" />
            <h1>{{ resource.prompts.prompts[1] }}</h1>
            <p v-for="item in explanation" :key="item">{{ item }}</p>
        </div>
        <div v-else-if="resource.prompts && resource.prompts.prompts" class="QuizResource__card wrong-answer">
            <img :src="resource.wrongSticker ? this.$cms.extractImage(resource.wrongSticker).url : null" />
            <h1>{{resource.prompts.prompts[2]}}</h1>
            <Button class="primary xsmall" @click="redoQuiz">{{resource.button}}</Button>
        </div>
    </div>
</template>
<script>
import Button from '../buttons/Button';

export default {
    name: 'DynamicQuizResource',
    components: { Button },
    data() {
        return {
            show: 'quiz'
        }
    },
    props: {
        resource: {
        type: Object,
        required: true,
      },
        prompt: String,
        choices: Array,
        explanation: Array
    },
    methods: {
        onSelectChoice: function (isCorrect, tag) {
            this.$gtm.trackEvent({
              event: 'quiz_click',
              value: tag,
            });
            if (isCorrect) {
                this.show = 'correct'
            } else {
                this.show = 'wrong'
            }
        },
        redoQuiz: function () {
            this.$gtm.trackEvent({
              event: 'quiz_try_again',
            });
            this.show = 'quiz'
        }
    }

}
</script>
<style lang="scss" scoped>
.QuizResource {
    max-width: 450px;
    margin-bottom: 24px;
}

.QuizResource__card {
    height: 433px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'rocgrotesk';
    text-align: center;
    color: var(--navy);
    border: 3px solid var(--navy);
    border-radius: 15px;

    h1 {
        line-height: 1.1;
        font-weight: 750;
        font-size: 24px;
        margin-bottom: 17px;
    }

    p {
        font-weight: 700;
        line-height: 1.3;
        font-size: 16px;
    }

    .QuizResource__choice {
        cursor: pointer;
        font-size: 14px;
        font-weight: 500;
        background-color: var(--white);
        padding: 16px 18.5px;
        border-radius: 6px;

        &:not(:last-child) {
            margin-bottom: 7px;
        }
    }
}

.QuizResource__card.quiz {
    padding: 32px 25px 30px;
    background-color: var(--medium-pink);

    .QuizResource__prompt {
        margin-bottom: 18px;
    }
}

.QuizResource__card.correct-answer {
    padding: 20px 25px 45px;
    background-color: var(--green-200);

    p:not(:last-child) {
        margin-bottom: 18px;
    }
}

.QuizResource__card.wrong-answer {
    padding: 16px;
    background-color: var(--light-red);

    h1 {
        margin-bottom: 20px;
    }

    img {
        margin-bottom: 20px;
    }

}
</style>
