<template>
    <div class="ResourcesFilterTag" :class="color">
        <p class="ResourcesFilterTag__title">{{ title }}</p>
    </div>
</template>

<script>

export default {
    name: "ResourcesFilterTag",
    props: {
        color: String,
        title: {
            type: String,
            required: true,
        },
    },
};
</script>

<style lang="scss" scoped>
.ResourcesFilterTag {
    cursor: pointer;
    display: inline-block;
    padding: 10px 17px;
    border-radius: 1000px;
    background-color: var(--pink);
    opacity: 0.8;
    margin-left: 4px;
    margin-bottom: 7px;

    @media only screen and (min-width: 701px) {
        padding: 12px 20px;
        margin-left: 6px;
        margin-bottom: 10px;
    }


    &.first {
        margin-left: 0px;
    }

    &__title {
        color: var(--navy);
        font-size: 16px;
        font-weight: 700;
        line-height: 1.3;
        font-family: 'rocgrotesk';

        @media only screen and (min-width: 701px) {
            font-size: 18px;
        }
    }

    &.active {
        border: 2px solid var(--navy);
        opacity: 1;
    }

    &.pink {
        background-color: var(--pink);
    }

    &.blue {
        background-color: var(--forest-200);
    }

    &.red {
        background-color: var(--red-400);
    }

    &.green {
        background-color: var(--green);
    }
}
</style>