import { Howl } from "howler";
import { soundStore } from "./soundStore.js";
import SoundConsts from "./soundConsts.js";

var backgroundMusicPlayer;
var timerSfxPlayer;
var soundPlayer;

export const audioPlayer = {
  created: function () {
    console.log("audioPlayer created");
  },
  data() {
    return { soundConsts: SoundConsts };
  },
  methods: {
    muted: function () {
      return soundStore.backgroundMusicMuted && soundStore.soundMuted;
    },
    backgroundMusicMuted: function () {
      return soundStore.backgroundMusicMuted;
    },
    muteBackgroundMusic: function () {
      console.log("audioPlayer: mute");
      if (backgroundMusicPlayer != undefined) {
        backgroundMusicPlayer.pause();
      }
    },
    unmuteBackgroundMusic: function () {
      console.log("audioPlayer: unmute");
      if (backgroundMusicPlayer != undefined) {
        backgroundMusicPlayer.play();
      }
    },
    // Sound effects
    soundMuted: function () {
      return soundStore.soundMuted;
    },
    playBackgroundMusic: function (soundId) {
      console.log(
        "audioPlayer playBackgroundMusic howl=" +
          backgroundMusicPlayer
      );

      if (backgroundMusicPlayer === undefined) {
        console.log("audioPlayer: playbg");

        var soundSrc;
        if (soundId == SoundConsts.BG_MUSIC_GAMEPLAY) {
          soundSrc = require("@/assets/sounds/background_music/feet_on_the_beach.mp3");
        } else if (soundId == SoundConsts.BG_MUSIC_WAITING_ROOM) {
          soundSrc = require("@/assets/sounds/background_music/neon_icecream.mp3");
        }

        backgroundMusicPlayer = new Howl({
          src: [soundSrc],
          html5: true,
          format: ["mp3"],
          loop: true,
          volume: 0.3,
          onplay: function () {
            // todo
          },
          onpause: function () {
            // todo
          },
          onend: function () {
            // todo
          },
          onseek: function () {
            // todo
          },
        });

        if (!soundStore.backgroundMusicMuted) {
          backgroundMusicPlayer.play();
        }
      }
    },
    stopBackgroundMusic: function () {
      if (backgroundMusicPlayer === undefined) {
        return;
      }

      backgroundMusicPlayer.unload();
      backgroundMusicPlayer = undefined;
    },
    playTenSecondCountdown: function () {
      console.log(
        "audioPlayer playTenSecondCountdown howl=" + timerSfxPlayer
      );

      if (soundStore.soundMuted) {
        return;
      }

      if (timerSfxPlayer === undefined) {
        console.log("audioPlayer: playTenSecondCountdown");
        var soundSrc = require("@/assets/sounds/game_countdown/10 Sec Countdown.mp3");

        timerSfxPlayer = new Howl({
          src: [soundSrc],
          html5: true,
          format: ["mp3"],
          onplay: function () {
            // todo
          },
          onpause: function () {
            // todo
          },
          onend: function () {
            // todo
          },
          onseek: function () {
            // todo
          },
        });

        timerSfxPlayer.mute(soundStore.soundMuted);
        timerSfxPlayer.play();
      }
    },
    stopTenSecondCountdown: function () {
      if (timerSfxPlayer === undefined) {
        return;
      }

      timerSfxPlayer.unload();
      timerSfxPlayer = undefined;
    },
    playSound: function (soundId) {
      console.log("audioPlayer playSound: " + soundId);

      if (soundStore.soundMuted) {
        return;
      }

      var soundSrc = require("@/assets/sounds/additional_sfx/generic_taps/Generic Tap 4c.mp3");
      if (soundId.startsWith(SoundConsts.WR_BLOB_PICKED)) {
        soundSrc = require("@/assets/sounds/waiting_room/wr_blob_choice/WR Blob " +
          soundId.slice(SoundConsts.WR_BLOB_PICKED.length) +
          ".mp3");
      } else if (soundId.startsWith(SoundConsts.WR_BLOB_JOINED)) {
        soundSrc = require("@/assets/sounds/waiting_room/Blob " +
          soundId.slice(SoundConsts.WR_BLOB_JOINED.length) +
          " Joins.mp3");
      } else {
        switch (soundId) {
          case SoundConsts.GENERIC_BUTTON:
            soundSrc = require("@/assets/sounds/additional_sfx/generic_taps/Generic Tap 4c.mp3");
            break;
          case SoundConsts.START_A_GAME_BUTTON:
            soundSrc = require("@/assets/sounds/landing_page/Tapping Start a Game Button.mp3");
            break;
          case SoundConsts.HOW_TO_PLAY_BUTTON:
            soundSrc = require("@/assets/sounds/landing_page/Tapping How to Play Button.mp3");
            break;
          case SoundConsts.INVITE_BUTTON:
            soundSrc = require("@/assets/sounds/waiting_room/Tapping Invite Button.mp3");
            break;
          case SoundConsts.PLAY_BUTTON:
            soundSrc = require("@/assets/sounds/waiting_room/Tapping Play Button.mp3");
            break;
          case SoundConsts.COPY_INVITE_BUTTON:
            soundSrc = require("@/assets/sounds/invite_players/Tapping Copy Button.mp3");
            break;
          case SoundConsts.SEND_TEXT_BUTTON:
            soundSrc = require("@/assets/sounds/invite_players/Tapping Send A Text Button.mp3");
            break;
          case SoundConsts.NEXT_BUTTON:
            soundSrc = require("@/assets/sounds/onboarding/Tapping Next.mp3");
            break;
          case SoundConsts.THREE_SEC_COUNTDOWN:
            soundSrc = require("@/assets/sounds/game_countdown/321 Countdown.mp3");
            break;
          case SoundConsts.TEN_SEC_COUNTDOWN:
            soundSrc = require("@/assets/sounds/game_countdown/10 Sec Countdown.mp3");
            break;
          case SoundConsts.INTERSTITIAL:
            soundSrc = require("@/assets/sounds/new_round_intro/Intersitial Round X Screen Alt.mp3");
            break;
          case SoundConsts.SELECT_RESPONSE_BUTTON:
            soundSrc = require("@/assets/sounds/game_round/Click Response Button.mp3");
            break;
          case SoundConsts.OTHERS_SUBMISSION:
            soundSrc = require("@/assets/sounds/game_round/Others Submission.mp3");
            break;
          case SoundConsts.WINNER_SELECTED:
            soundSrc = require("@/assets/sounds/game_round/Winner Selected.mp3");
            break;
          case SoundConsts.WINNER_STICKER:
            soundSrc = require("@/assets/sounds/game_round/Winner Sticker.mp3");
            break;
          case SoundConsts.WINNERS_RESPONSE:
            soundSrc = require("@/assets/sounds/game_round/Winners Response.mp3");
            break;
          case SoundConsts.HOT_TIPS:
            soundSrc = require("@/assets/sounds/game_round/Hot Tips.mp3");
            break;
          case SoundConsts.IN_THE_LEAD_STICKER:
            soundSrc = require("@/assets/sounds/game_round/In The Lead Sticker.mp3");
            break;
          case SoundConsts.YOURE_THE_JUDGE:
            soundSrc = require("@/assets/sounds/new_round_intro/You're The Judge.mp3");
            break;
          case SoundConsts.WINNER_CELEBRATION:
            soundSrc = require("@/assets/sounds/end_of_game/Celebration of Winner.mp3");
            break;
          case SoundConsts.EXPLORE_MORE_BUTTON:
            soundSrc = require("@/assets/sounds/end_of_game/Tapping Explore More Button.mp3");
            break;
          case SoundConsts.PLAY_AGAIN_BUTTON:
            soundSrc = require("@/assets/sounds/end_of_game/Tapping Play Again Button.mp3");
            break;
          case SoundConsts.CTA_BUTTON:
            soundSrc = require("@/assets/sounds/resources/CTA.mp3");
            break;
        }
      }

      soundPlayer = new Howl({
        src: [soundSrc],
        html5: true,
        format: ["mp3"],
        onplay: function () {
          // todo
        },
        onpause: function () {
          // todo
        },
        onend: function () {
          // todo
        },
        onseek: function () {
          // todo
        },
      });
      soundPlayer.mute(soundStore.soundMuted);
      soundPlayer.play();
    },
  },
};
