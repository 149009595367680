<template>
    <div class="ResourcesFilter">

        <ResourcesFilterTag 
            v-for="(category, index) in categories"
            :key="index"
            :class="[(firstResource === category.title && 'active'), (index === 0 && 'first')]"
            :color="category.color"
            :title="category.title"
            @click="onClickFilter(category.title, category.title)"
        />
    </div>
</template>
<script>
import ResourcesFilterTag from './ResourcesFilterTag';

export default {
    name: 'DynamicResourcesFilter',
    components: { ResourcesFilterTag },
    props: { firstResource: String, isResourcesPage: Boolean,
        categories: {
        type: Object,
        required: true,
      } },
    methods: {
        onClickFilter: function (categoryName, title) {
            this.$emit('setFirstResource', categoryName)

            this.$gtm.trackEvent({
                event: 'resource_category_click',
                module_name: this.isResourcesPage ? 'Resources page' : 'End Game page',
                click_text: title,
                category: categoryName
            });
        }
    }
}
</script>
<style lang="scss" scoped>
.ResourcesFilter {
    text-align: center;
    margin-bottom: 20px;
}
</style>