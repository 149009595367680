<template >
    <ResourcesCard class="share-scenario-link" category="Online Safety" :linkClick="openSubmitScenario"
        linkText="Share your story" :gif="resource.image ?  this.$cms.extractImage(resource.image).url : null" :color="resource.color">
        <p>{{resource.question}}</p>
        <p class="secondary">{{ resource.subtext }}</p>
    </ResourcesCard>
    <FullScreenModal class="SubmitScenario" ref="submitScenarioScreen" :color="resource.color" noexit hideStickers>
        <template v-slot:header>
            <div class="FullScreenModal__header">
                <div class="FullScreenModal__header-back-button"><img @click="closeSubmitScenario"
                        src="/assets/ui_elements/arrowBack.svg" />
                </div>
            </div>
        </template>
        <template v-slot:body>
            <div class="SubmitScenario__content">
                <img class="SubmitScenario__graphic" :src="resource.image ? this.$cms.extractImage(resource.image).url : null" :color="resource.color" />
                <p class="SubmitScenario__question">{{resource.question }}</p>
                <p class="SubmitScenario__subtext">{{ resource.subtext }}
                </p>
                <textarea v-model="userScenarioInput" :placeholder="resource.placeholder"/>
                <p class="SubmitScenario__confidential">{{ resource.confidentialMessage }}</p>
            </div>
            <a class="SubmitScenario__button" :class="!userScenarioInput.trim() && 'disabled'">
                <Button class="primary" @click="submitScenario"
                    @mousedown="playSound(soundConsts.PLAY_AGAIN_BUTTON)">{{resource.buttonShare}}</Button>
            </a>
        </template>
    </FullScreenModal>
    <FullScreenModal class="ScenarioSubmitted" ref="scenarioSubmittedScreen" color={{resource.color}} noexit hideStickers>
        <template v-slot:body>
            <div class="ScenarioSubmitted__content">
                <img class="SubmitScenario__graphic" :src="resource.image ? this.$cms.extractImage(resource.image).url : null" :color="resource.color" />
                <h1>{{ resource.thanksHeading }}</h1>
                <p>{{resource.thanksSubtext}}</p>
                <Button class="primary" @click="onPressScenarioSubmittedDone"
                    @mousedown="playSound(soundConsts.PLAY_AGAIN_BUTTON)">{{resource.buttonDone}}</Button>
            </div>
        </template>
    </FullScreenModal>
</template>
<script>
import ResourcesCard from '../../ResourcesCard';
import FullScreenModal from '/src/components/modal/full-screen-modal'
import Button from "../buttons/Button";
import { audioPlayer } from "/src/soundfx/audioPlayer.js";

export default {
    name: "DynamicSubmitScenarioResource",
    mixins: [audioPlayer],
    components: { ResourcesCard, FullScreenModal, Button },
    props: { moduleName: String,
        resource: {
        type: Object,
        required: true,
        }
    
    },
    data: () => ({
        userScenarioInput: ''
    }),
    methods: {
        openSubmitScenario: function () {
            this.$refs.submitScenarioScreen.openModal()
        },
        closeSubmitScenario: function () {
            this.$refs.submitScenarioScreen.closeModal()
        },
        openScenarioSubmitted: function () {
            this.$refs.scenarioSubmittedScreen.openModal()
        },
        onPressScenarioSubmittedDone: function () {
            this.$refs.scenarioSubmittedScreen.closeModal()
            this.closeSubmitScenario()
        },
        submitScenario: function () {
            this.$gtm.trackEvent({
                event: 'user_scenario_submit',
                user_scenario: this.userScenarioInput,
                module_name: this.moduleName
            });

            this.openScenarioSubmitted()
            this.closeSubmitScenario()
            this.userScenarioInput = ''
        },
    }
}
</script>
<style lang="scss" scoped>
// :deep() {}

:deep() {
    .modal {
        overflow-y: visible;
    }

    .modal__dialog {
        width: 100vw;

    }

    .modal__footer {
        margin-top: 0;
    }

    max-width: 500px;

    img {
        width: 95px;
        margin: 0 auto 25px auto;
    }

    p.secondary {
        font-size: 16px;
        font-weight: 500;
        margin-top: 11px;
    }

    @media screen and (min-width:701px) {
        img {
            width: 115px;
            margin-bottom: 30px;
        }

        p.secondary {
            font-size: 18px;
            margin-top: 15px;
        }
    }

    .FullScreenModal__header {
        width: 100vw;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
        margin-top: 21px;

        h1 {
            font-size: 32px;
            font-family: 'cheee';
            font-weight: 520;
            font-family: 32px;
            line-height: 1.4;
            letter-spacing: .05em;
            color: var(--navy);
        }

        &-back-button {
            padding: 5px;
            cursor: pointer;
            margin-left: -5px;

            img {
                height: 21px;
                width: 21px;
            }
        }

        @media only screen and (min-width: 701px) {
            h1 {
                font-size: 46px;
            }

            &-back-button {
                margin-left: 0;
                padding: 10px;

                img {
                    height: 35px;
                    width: 35px;
                }
            }
        }
    }
}



.SubmitScenario {
    color: var(--navy);
    overflow: visible;

    .FullScreenModal__header {
        margin-top: 0px;
        padding: 0px;
    }


    :deep(.modal__dialog) {
        justify-content: flex-start;
    }

    :deep(.modal__header) {
        padding: 47px 28px 0;

        @media screen and (max-height: 745px) and (max-width: 440px) {
            padding-top: 20px;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__graphic {
        width: 100px;
        margin-bottom: 28px;

        @media screen and (max-height: 745px) and (max-width: 440px) {
            margin-bottom: 20px;
        }

        @media screen and (min-width: 701px) {
            width: 115px;
        }
    }

    p {
        text-align: center;
        line-height: 1.3;
        font-weight: 500;
        max-width: 550px;

        &.SubmitScenario__question {
            font-weight: 750;
            font-size: 18px;
            margin-bottom: 11px;
        }

        &.SubmitScenario__subtext {
            font-size: 16px;
        }

        &.SubmitScenario__confidential {
            font-size: 14px;
            margin-bottom: 52px;

            @media screen and (max-height: 745px) and (max-width: 440px) {
                margin-bottom: 25px;
            }
        }

        @media screen and (min-width: 701px) {
            &.SubmitScenario__question {
                font-size: 22px;
                margin-bottom: 15px;
            }

            &.SubmitScenario__subtext {
                font-size: 19px;
            }

            &.SubmitScenario__confidential {
                font-size: 16px;
                margin-bottom: 57px;
            }
        }
    }

    textarea {
        resize: none;
        font-size: 16px;
        font-family: 'rocgrotesk';
        font-weight: 500;
        line-height: 1.3;
        width: 100%;
        max-width: 550px;
        height: 177px;
        border: 2px solid var(--navy);
        border-radius: 8px;
        background-color: var(--white);
        padding: 18px;
        margin: 55px 0 35px 0;

        @media screen and (max-height: 745px) and (max-width: 440px) {
            margin: 20px 0 15px 0;
            height: 155px;
        }

        @media screen and (min-width: 701px) {
            font-size: 18px;
        }
    }

    &__button {
        width: 100%;
        margin-bottom: 37px;
        text-decoration: none;

        &.disabled {
            opacity: 0.65;
            pointer-events: none;
            cursor: default;
        }
    }

}

.ScenarioSubmitted {
    overflow: visible;

    :deep(.modal__dialog) {
        padding-top: 100px;
    }

    &__content {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        color: var(--navy);

        img {
            margin-bottom: 43px;
        }

        h1 {
            font-family: 'cheee';
            font-weight: 520;
            font-size: 40px;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            margin-bottom: 28px;

        }

        p {
            font-family: 'rocgrotesk';
            font-weight: 500;
            font-size: 18px;
            line-height: 1.3;
            text-align: center;
            margin-bottom: 100px;
        }

        .button {
            width: 100%;
            max-width: 400px;
        }


        @media screen and (min-width: 701px) {
            img {
                width: 125px;
                margin-bottom: 50px;
            }

            h1 {
                font-size: 50px;
                margin-bottom: 44px;
            }

            p {
                font-size: 22px;
                margin-bottom: 110px;
            }
        }
    }
}
</style>