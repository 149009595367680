<template>
    <div class="DefaultResource">

      <DynamicResourcesCard
        v-if="resource.type === 'instagram'"
        :category="resource.category"
        :poster="resource.poster ? this.$cms.extractImage(resource.poster).url : null"
        :thumbnail="resource.thumbnail ? this.$cms.extractImage(resource.thumbnail).url : null"
        :instagram="resource.contentLink"
        :hashtags="resource.hashtags"
      />

      <DynamicResourcesCard
        v-else-if="resource.type === 'tiktok'"
        :category="resource.category"
        :tiktok="resource.contentLink"
        :hashtags="resource.hashtags"
      />

      <DynamicResourcesCard
        v-else-if="resource.type === 'gif'"
        :category="resource.category"
        :gif="resource.thumbnail ? this.$cms.extractImage(resource.thumbnail).url : null"
        class="no-whitespace"
      >

      {{ resource.description }}

      </DynamicResourcesCard>

      <DynamicResourcesCard
        v-else-if="resource.type === 'linkContents' && (!resource.list || resource.list.list.length === 0)" 
        :category="resource.category" 
        :link="resource.linkURL"
        :color="resource.color"
        :linkText="resource.linkText"
        :gif="resource.gif ? this.$cms.extractImage(resource.gif).url : null"
      >
        {{resource.text}}
      </DynamicResourcesCard>

      <DynamicResourcesCard
        v-else-if="resource.type === 'linkContents' && (resource.list && resource.list.list.length > 0)" 
        :category="resource.category" 
        :link="resource.linkURL"
        :linkText="resource.linkText"
      >
        <div class="Resources__formatted">
            <h1>{{ resource.text }}</h1>
            <ul>
              <li>{{resource.list.list[0]}}</li>
              <li>{{resource.list.list[1]}}</li>
              <li>{{resource.list.list[2]}}</li>
            </ul>
          </div>
      </DynamicResourcesCard>

      <DynamicResourcesCard
        v-else-if="resource.type === 'emergencyContacts' && resource.contactType === 'simple'" 
        :category="resource.category" 
        :color="resource.color"
      >
          <div class="Resources__formatted">
            <h1> {{ resource.name }}</h1>
            <p>{{ resource.description }} <a
                @click="goToLink(resource.link, resource.category, resource.linkText)" style=" cursor: pointer;">{{ resource.linkText }}.</a>
            </p>
            <h2><a class="no-underline" @click="goToPhone(resource.mainContact, resource.category)"
                style="cursor: pointer;">{{resource.mainContact}}</a></h2>
          </div>
      </DynamicResourcesCard>

      <DynamicResourcesCard
        v-else-if="resource.type === 'emergencyContacts' && resource.contactType === 'withLinkDescription'" 
        :category="resource.category" 
        :color="resource.color"
        phone
      >
          <div class="Resources__formatted">
            <p>{{ resource.description}}</p>
            <h2><a @click="goToPhone(resource.mainContact, resource.name)"
                style="cursor: pointer;text-decoration: underline;">{{ resource.mainContact }}</a></h2>
            <p>{{ resource.secondaryDescription }} <a
                @click="goToLink(resource.link, resource.name, resource.linkText)"
                target="_blank" style="cursor: pointer;text-decoration: underline;">{{ resource.linkText}}.</a></p>
          </div>
      </DynamicResourcesCard>

      <DynamicResourcesCard
        v-else-if="resource.type === 'emergencyContacts' && resource.contactType === 'withSecondaryContact'" 
        :category="resource.category" 
        :color="resource.color"
        phone
      >
          <div class="Resources__formatted">
            <h1>{{resource.name}}</h1>
            <p>{{resource.description}}</p>
            <h2>{{resource.mainContactMethod}} {{ resource.mainContact }}</h2>
            <p> or <span style="font-weight: 700;">{{ resource.secondaryContactMethod.split('.')[0].trim() }}.</span> {{resource.secondaryContactMethod.split('.')[1].trim()}}.
              <a @click="goToLink(resource.link, resource.category, resource.name)"
                style="cursor: pointer;">{{resource.linkText}}</a>
              {{resource.secondaryDescription}}
            </p>
            <h2><a class="no-underline" @click="goToPhone(resource.secondaryContact, resource.category)"
                style="cursor: pointer;">{{ resource.secondaryContact}}</a></h2>
          </div>
      </DynamicResourcesCard>
  

      <DynamicResourcesCard
        v-else-if="resource.type === 'youtube'" 
        :category="resource.category" :video="resource.contentLink"
      >
        {{resource.description}}
      </DynamicResourcesCard>

      <DynamicResourcesCard
        v-else-if="resource.type === 'quotes'" 
        :category="resource.category" 
        :color="resource.color"
        :quote="resource.text"
        :cite="resource.author"
      >
        {{resource.description}}
      </DynamicResourcesCard>

      <DynamicResourcesCard
        v-else-if="resource.type === 'hotTip'"
        :category="resource.category"
        :hotTipSticker="resource.sticker ?  this.$cms.extractImage(resource.sticker).url : null"
        :hottip=true
      >

      <div v-html="resource.text"></div>

      </DynamicResourcesCard>
  
      <!-- Default Case -->
      <div v-else>
        <!-- Show the default view -->
        <!--Default view -->
      </div>
    </div>
  </template>
  
  <script>
  // Import the components
  import DynamicResourcesCard from "../../DynamicResourcesCard.vue";
  
  export default {
    name: "DefaultResource",
    components: {
      DynamicResourcesCard,
    },
    props: {
      resource: {
        type: Object,
        required: true,
      }
    },
    methods: {
      goToLink(url, cat, linkText) {
      this.$gtm.trackEvent({
        event: 'resource_link_click',
        module_name: cat,
        link_url: url,
        click_text: linkText,
        category: cat
      });
      window.open(url);
    },
    goToPhone(num, cat) {
      this.$gtm.trackEvent({
        event: 'resource_link_click',
        module_name: cat,
        link_url: 'tel:' + num,
        click_text: num,
      });
      window.open('tel:' + num);
    }
    }
  };
  </script>
  
  <style lang="scss" scoped>
  .Resources {
    display: flex;
    flex-direction: column;
    background-color: var(--light-cream);
  
    &__heading {
      order: -3;
  
      h2 {
        text-align: center;
        color: var(--navy);
        font-family: 'cheee';
        font-size: 40px;
        font-weight: 520;
        margin-bottom: 1.75rem;
        margin-top: 20px;
  
        @media only screen and (min-width: 701px) {
          font-size: 58px;
          margin-bottom: 2.5rem;
          margin-top: 2.5rem;
        }
      }
    }
  
    &__filter {
      order: -2
    }
  
    .first {
      order: -1;
      padding-top: 20px;
    }
  
    &__section {
      padding: 0px var(--spacing) 47px;
      min-height: 100vh;
    }
  
  
    &__intro {
      position: relative;
      text-align: center;
      margin-bottom: var(--spacing-medium);
      font-size: 14px;
      line-height: 1.4;
  
      @media only screen and (min-width: 701px) {
        max-width: 900px;
        margin-left: auto;
        margin-right: auto;
        font-size: 22px;
      }
  
      h2 {
        color: var(--navy);
        font-size: 24px;
        font-weight: 750;
        line-height: 1.3;
        width: 100%;
        margin-bottom: var(--spacing-small);
        padding: 0 24px;
  
        @media only screen and (min-width: 701px) {
          max-width: 650px;
          margin-left: auto;
          margin-right: auto;
        }
      }
  
      p {
        color: var(--navy);
        font-weight: 500;
        width: 100%;
        font-size: 14px;
        line-height: 1.4;
        max-width: 280px;
        margin: 0 auto;
  
        @media only screen and (min-width: 701px) {
          max-width: 650px;
        }
      }
    }
  
    //&__intro
  
    &__formatted {
      position: relative;
  
      a {
        cursor: pointer;
        text-decoration: underline;
      }
  
      a.no-underline {
        text-decoration: none;
      }
  
      h1 {
        font-size: 22px;
        line-height: 1.4;
        margin-bottom: 1em;
      }
  
      h2 {
        font-size: 18px;
        margin-bottom: 1em;
      }
  
      p {
        margin-bottom: 1em;
        font-weight: 400;
      }
  
      ul {
        font-size: 14px;
        font-weight: 400;
        text-align: left;
        margin-bottom: var(--spacing-medium);
  
        li {
          position: relative;
          margin: 0 0 1.4em 40px;
  
          &::before {
            position: absolute;
            content: "";
            width: 20px;
            height: 20px;
            border: 2px solid var(--navy);
            border-radius: 8px;
            left: -40px;
            top: 6px;
          }
        }
      }
    }
  
    &__back {
      position: absolute;
      top: 10px;
      left: 0;
      width: 18px;
      cursor: pointer;
    }
  
    &__cards {
      width: 100%;
      max-width: 450px;
      margin: 0 auto;
  
      @media only screen and (min-width: 701px) {
        max-width: 650px;
        columns: 2;
        column-gap: var(--spacing-medium);
      }
    }
  }
  </style>