export default {
  BG_MUSIC_WAITING_ROOM: "bg_music_waiting_room",
  BG_MUSIC_GAMEPLAY: "bg_music_gameplay",
  GENERIC_BUTTON: "generic_button",
  WR_BLOB_PICKED: "wr_blob_picked_",
  WR_BLOB_JOINED: "wr_blob_joined_",
  START_A_GAME_BUTTON: "start_a_game_button",
  HOW_TO_PLAY_BUTTON: "how_to_play_button",
  INVITE_BUTTON: "invite_button",
  PLAY_BUTTON: "play_button",
  COPY_INVITE_BUTTON: "copy_invite_button",
  SEND_TEXT_BUTTON: "send_text_button",
  NEXT_BUTTON: "next_button",
  THREE_SEC_COUNTDOWN: "321_countdown",
  TEN_SEC_COUNTDOWN: "10_sec_countdown",
  INTERSTITIAL: "interstitial",
  SELECT_RESPONSE_BUTTON: "select_response_button",
  OTHERS_SUBMISSION: "others_submission",
  WINNER_SELECTED: "winner_selected",
  WINNER_STICKER: "winner_sticker",
  WINNERS_RESPONSE: "winners_response",
  HOT_TIPS: "hot_tips",
  IN_THE_LEAD_STICKER: "in_the_lead_sticker",
  YOURE_THE_JUDGE: "youre_the_judge",
  WINNER_CELEBRATION: "winner_celebration",
  EXPLORE_MORE_BUTTON: "explore_more_button",
  PLAY_AGAIN_BUTTON: "play_again_button",
  CTA_BUTTON: "cta_button",
};
