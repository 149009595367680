<template>
  <transition name="fade">
    <img :src="image" class="sticker-regular" v-if="show" />
  </transition>
</template>

<script>
export default {
  name: "Sticker",
  components: {},
  props: {
    image: String,
    text: String,
    delay: Number,
  },
  data() {
    return {
      show: false,
    };
  },
  mounted() {
    // console.log('this');
    let time = this.delay * 1000;
    setTimeout(() => {
      this.show = true;
    }, time);
  },
};
</script>

<style lang="scss">
.sticker-regular {
  display: block;
  transition: transform 0.16s $ease-1;
  width: 100%;

  /* &:hover {
    transform: scale(1.15) rotate(-10deg);
  } */
}

/* .sPop-enter-active, .sPop-leave-active {
  transition: transform 0.16s $ease-3;
  transform: scale(1);
}

.sPop-enter{
  transform: scale(1.15);
} */

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.16s $ease-3 0.33s, transform 0.16s $ease-3 0.33s;
  transform: scale(1);
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: scale(1.15);
}

@keyframes dance {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(-15deg);
  }
}
</style>
