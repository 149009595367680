<template>
  <div class="modal FullScreenModal" v-if="show">
    <div v-if="!hideStickers" class="modal__logo-bar">
      <Header class="clear">
        <template v-slot:right>
          <img class="eyes_sticker" src="/assets/ui_elements/eyesSticker.svg" />
          <img class="phone_sticker" src="/assets/ui_elements/phoneSticker.svg" />
        </template>
      </Header>
    </div>

    <div class="modal__dialog" :class="color">
      <div class="modal__header" v-if="hasHeaderSlot">
        <slot name="header" />
      </div>

      <div class="modal__body">
        <slot name="body" />
      </div>

      <div class="modal__footer">
        <slot name="footer" />
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../header";
export default {
  name: "FullScreenModal",
  components: { Header },
  props: {
    noexit: {
      default: false,
      type: Boolean,
    },
    hideStickers: Boolean,
    color: String
  },
  data() {
    return {
      show: false,
    };
  },
  computed: {
    hasHeaderSlot() {
      return !!this.$slots.header;
    },
  },
  methods: {
    closeModal() {
      this.show = false;
      document
        .querySelector("body")
        .classList.remove("overflow-hidden");
    },
    openModal() {
      this.show = true;
      document.querySelector("body").classList.add("overflow-hidden");
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  overscroll-behavior: contain;
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9;

  &__logo-bar {
    position: absolute;
    z-index: 10;
    width: 100%;
  }

  .eyes_sticker {
    position: absolute;
    top: -30px;
    right: -15px;
    width: 100px;
    z-index: 11;
  }

  .phone_sticker {
    position: absolute;
    top: -5px;
    right: -50px;
    width: 100px;
  }

  @media only screen and (min-width: 701px) {
    .eyes_sticker {
      top: -36px;
      right: 20px;
      width: 140px;
    }

    .phone_sticker {
      position: absolute;
      top: -5px;
      right: -36px;
      width: 140px;
    }

  }

  &__dialog {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: var(--app-height);
    background-color: var(--light-cream);
    overflow: hidden;

    /* margin: 50px auto; */
    border-radius: 5px;
    z-index: 2;


    &.light-pink {
      background-color: var(--light-pink);
    }
  }

  &__header {
    padding: 20px 20px 10px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex: none;
  }

  &__body {
    overscroll-behavior: contain;
    position: relative;
    padding: 10px 20px 10px;
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    width: auto;
    flex-direction: column;
    align-items: center;
    -webkit-overflow-scrolling: touch;
  }

  &__footer {
    position: absolute;
    width: 100%;
    padding: 0px 20px;
    flex: none;
    bottom: 31px;
    display: flex;
    justify-content: center;

    @media only screen and (min-width: 701px) {
      position: relative;
      bottom: 0;
      margin-top: 45px;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
