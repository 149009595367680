<template>
  <svg width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg"
    :class="small && 'small'">
    <path d="M1 9.5L20.8039 9.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M13.8721 1.5L20.8034 9.5L13.8721 17.5" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
</template>

<script>
export default {
  name: "IconArrowRight",
  props: {
    color: String,
    small: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
svg {
  width: 100%;

  &.small {
    path {
      stroke-width: 4;
    }
  }

  path {
    stroke: var(--white);
    stroke-width: 2;
  }

  &#arrowPink {
    path {
      stroke: var(--pink);
    }
  }

  &.navy {
    path {
      stroke: var(--navy);
    }
  }
}
</style>
