import { reactive } from "vue";

export const soundStore = reactive({
  backgroundMusicMuted: false,
  toggleBackgroundMusic() {
    this.backgroundMusicMuted = !this.backgroundMusicMuted;
  },
  soundMuted: false,
  toggleSound() {
    this.soundMuted = !this.soundMuted;
  },
});
