<template>
  <div class="ResourcesCard" :class="[color, clickable && 'clickable', hottip && 'hottip']">
    <Sticker image="/assets/ui_elements/hotTip.svg" class="sticker-hottip" :delay="0.1" v-if="hottip" />
    <Sticker image="/assets/ui_elements/phoneSticker.svg" class="sticker-phone" :delay="0.1" v-if="phone" />
    <div class="ResourcesCard__inner">
      <LiteYouTubeEmbed :id="video" title="YouTube video player" poster="hqdefault" wrapperClass="ResourcesCard__video"
        v-if="video" />
      <!-- <div class="ResourcesCard__video" v-if="video">
        <iframe 
          :src="videoUrl"
          title="YouTube video player" 
          frameborder="0" 
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
          allowfullscreen>
        </iframe>
      </div> -->
      <div class="ResourcesCard__gif" v-if="gif">
        <img :src="gif" />
      </div>
      <div class="ResourcesCard__tiktok" v-if="tiktok">
        <div class="poster">
          <img class="tiktok-play" src="/assets/ui_elements/play-button.svg">
          <img @click="goToLink(tiktok)" @mousedown="playSound(soundConsts.CTA_BUTTON)" :src="tikTokPoster" />
        </div>
        <div>#selflove</div>
      </div>
      <div class="ResourcesCard__instagram" v-if="instagram">
        <div v-if="poster" class="poster">
          <img class="instagram-play" src="/assets/ui_elements/play-button.svg">
          <img @click="goToLink(instagram)" @mousedown="playSound(soundConsts.CTA_BUTTON)" :src="poster" />
        </div>
        <div v-if="hashtags">{{ hashtags }}</div>
        <div class="thumbnail" v-if="thumbnail" @click="goToLink(instagram)"
          @mousedown="playSound(soundConsts.CTA_BUTTON)">
          <img class="instagram-thumbnail" :src="thumbnail" />
        </div>
      </div>
      <div class="ResourcesCard__body" :class="hasArrow && 'hasArrow'">
        <blockquote v-if="quote">
          {{ quote }}
          <figcaption> - {{ cite }}</figcaption>
        </blockquote>
        <slot />
        <IconArrowRight v-if="hasArrow" class="arrow navy" />
      </div>
      <div class="ResourcesCard__footer" v-if="link || linkClick">
        <Button :gaText="link" class="primary xsmall no-margin" @click="buttonLink"
          @mousedown="playSound(soundConsts.CTA_BUTTON)">{{
  linkText
          }}</Button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Button from "./components/buttons/Button";
import Sticker from "./components/stickers/Sticker";
import { audioPlayer } from "/src/soundfx/audioPlayer.js";
import LiteYouTubeEmbed from 'vue-lite-youtube-embed';
import IconArrowRight from "./components/icons/IconArrowRight.vue";

export default {
  name: "ResoucesCard",
  mixins: [audioPlayer],
  components: {
    Button,
    LiteYouTubeEmbed,
    Sticker,
    IconArrowRight
  },
  props: {
    category: String,
    cite: String,
    color: String,
    title: {
      type: String,
      required: true,
    },
    gif: String,
    hottip: Boolean,
    link: String,
    linkClick: Function,
    linkText: {
      type: String,
    },
    phone: Boolean,
    quote: String,
    tiktok: String,
    instagram: String,
    thumbnail: String,
    poster: String,
    video: String,
    clickable: Boolean,
    hasArrow: Boolean,
    hashtags: String
  },
  data: () => ({
    tikTokPoster: '',
    tikTokTitle: '',
  }),
  methods: {
    goToLink(url) {
      // Google Tag Manager Event
      this.$gtm.trackEvent({
        event: 'resource_link_click',
        module_name: this.category,
        click_text: this.linkText,
        link_url: url,
        category: this.category
      });

      window.open(url);
    },
  },
  computed: {
    // videoUrl() {
    //   const code = this.video.split('watch?v=')[1];
    //   const url = "https://www.youtube.com/embed/" + code + "?controls=0";
    //   return url;
    // }
    buttonLink() {
      return this.linkClick ? this.linkClick : () => this.goToLink(this.link)
    }
  },
  mounted() {
    var oembedUrl = 'https://www.tiktok.com/oembed?url=' + 'https://www.tiktok.com/@saharrooo/video/7072530537331576069';

    axios.get(oembedUrl)
      .then(response => {
        console.log(response.data)
        this.tikTokPoster = response.data.thumbnail_url;
        this.tikTokTitle = response.data.title;
      })
  }
};
</script>

<style lang="scss">
.ResourcesCard {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: var(--spacing);
  background-color: var(--white);
  color: var(--navy);
  border: 3px solid var(--navy);
  border-radius: var(--radius-medium);
  break-inside: avoid;

  &:last-child {
    margin-bottom: 0;
  }

  &.clickable {
    cursor: pointer;

    .sticker-hottip {
      top: -30px;
    }

    .ResourcesCard__inner {
      padding: 21px 24px 21px 17px !important;
    }

    .ResourcesCard__body {
      text-align: left;
      font-size: 18px;
      line-height: 1.3;
      font-weight: 750;


      @media only screen and (min-width: 701px) {
        font-size: 22px;
        padding-left: 10px;
      }
    }
  }

  .ResourcesCard__body.hasArrow {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .arrow {
      width: auto;
    }
  }

  &.red-100 {
    background-color: var(--red-100);
  }

  &.pink {
    background-color: var(--medium-pink);
  }

  &.light-pink {
    background-color: var(--light-pink);
  }

  &.green {
    background-color: var(--green);
  }

  &.green-200 {
    background-color: var(--green-200);
  }

  &.green-100 {
    background-color: var(--green-100);
  }

  &.forest-400 {
    background-color: var(--forest-400);
  }

  &.blue,
  &.light-blue {
    background-color: var(--light-blue);
  }

  &.navy {
    background-color: var(--navy);
    color: var(--white);

    a,
    a:visited {
      color: var(--white);
    }
  }

  &.dark-cream {
    background-color: var(--dark-cream);
  }

  &__inner {
    padding: var(--spacing);
    border-radius: 11px;
    overflow: hidden;
  }

  &.hottip &__inner {
    padding: 35px var(--spacing) var(--spacing);
  }

  .sticker-hottip {
    position: absolute;
    width: 138px;
    height: auto;
    top: -20px;
    left: -40px;
    transform: translate3d(0, 0, 0);

    +.ResourcesCard__body {
      margin-top: var(--spacing-small);
    }
  }

  .sticker-phone {
    position: absolute;
    width: 70px;
    height: auto;
    top: -25px;
    left: -35px;
  }

  &__title {
    flex: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 20px;
    font-weight: 800;
    line-height: 1.344;
    margin-bottom: var(--spacing);
  }

  &__body {
    font-size: 18px;
    font-weight: 750;
    line-height: 1.3;
    text-align: center;

    blockquote {
      font-size: 18px;
    }

    figcaption {
      font-size: 14px;
      font-weight: 400;
      margin-top: var(--spacing-small);
    }

    @media screen and (min-width:701px) {
      font-size: 20px;
    }
  }

  &__gif {
    img {
      display: block;
      width: 100%;
      margin-bottom: var(--spacing) !important;
    }
  }

  &.no-whitespace {
    img {
      position: relative;
      width: calc(100% + var(--spacing) + var(--spacing));
      margin: calc(var(--spacing) * -1);
    }
  }

  &__video {
    position: relative;
    padding-bottom: calc(56.25% + 48px);
    width: calc(100% + var(--spacing) + var(--spacing));
    margin: calc(var(--spacing) * -1) calc(var(--spacing) * -1) var(--spacing);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    cursor: pointer;

    &:hover {
      .lty-playbtn {
        background-color: red;
        opacity: 1;
      }
    }

    &.lyt-activated {
      cursor: unset;

      &:before,
      >.lty-playbtn {
        opacity: 0;
        pointer-events: none;
      }
    }

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
    }

    .lty-playbtn {
      width: 70px;
      height: 46px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      background-color: #212121;
      z-index: 1;
      opacity: 0.8;
      border-radius: 14%;
      transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        border-color: transparent transparent transparent #fff;
        border-style: solid;
        border-width: 11px 0 11px 19px;
      }
    }
  }

  &__tiktok,
  &__instagram {

    .tiktok-play,
    .instagram-play {
      position: absolute;
      width: 32px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      pointer-events: none;
    }

    .poster {
      position: relative;
      width: calc(100% + var(--spacing) + var(--spacing));
      margin: calc(var(--spacing) * -1) calc(var(--spacing) * -1) var(--spacing);
      cursor: pointer;
    }

    .thumbnail {
      position: relative;
      width: calc(100% + var(--spacing) + var(--spacing));
      margin: calc(var(--spacing) * -1);
      cursor: pointer;
    }

    img {
      display: block;
      width: 100%;
    }
  }

  &__table {
    flex: none;
  }

  &__footer {
    margin-top: var(--spacing);
  }
}
</style>