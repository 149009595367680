<template>
  <div class="header">
    <div class="header__logo">
      <div @click="logoClick">
        <img src="/assets/logos/logo-likely-story.svg" />
      </div>
    </div>

    <div class="header__center" v-if="hasCenterSlot">
      <slot name="center" />
      <slot></slot>
    </div>

    <div class="header__right" v-if="hasRightSlot">
      <slot name="right" />
    </div>
  </div>
</template>

<script>
import { LANDING_PAGE_URL } from '/src/consts/urls'

export default {
  name: "Header",
  computed: {
    hasCenterSlot() {
      return !!this.$slots.center;
    },
    hasRightSlot() {
      return !!this.$slots.right;
    }
  },
  methods: {
    logoClick: function () {
      // GTM Submission
      this.$gtm.trackEvent({
        event: 'nav_click',
        module_name: 'Header',
        nav_type: 'home logo',
        link_url: LANDING_PAGE_URL,
      });
      // Go home
      window.location.href = LANDING_PAGE_URL;
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  display: grid;
  grid-template-columns: 100px auto 100px;
  flex: none;
  padding: var(--spacing);
  background-color: var(--white);

  .EndGame & {
    &__logo a img {
      width: 112px;
    }
  }

  &.clear {
    background-color: transparent;
  }

  &.xs-logo-phone img {
    width: 70px;
  }

  &__logo {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;

    >div {
      display: block;

      img {
        display: block;
        width: 113px;
        height: auto;

        @media only screen and (min-width: 701px) {
          width: 128px;
        }
      }
    }
  }

  &__center {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'cheee';
    color: var(--red);
    font-size: 18px;
    font-weight: 500;
    text-align: center;

    @media only screen and (min-width: 701px) {
      font-size: 40px;
    }
  }

  &__right {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    flex: auto;
  }
}
</style>
