<template>
  <div class="button" :class="[size && size, arrow && 'arrow']">
    <button :data-ga-button="gaText">
      <slot>{{ $t('button') }}</slot>
      <div class="button__arrow" v-if="arrow">
        <IconArrowRight id="arrowWhite" :small="size == 'xsmall' && true" />
      </div>
    </button>
  </div>
</template>

<script>
import IconArrowRight from "../icons/IconArrowRight";

export default {
  name: "Button",
  components: { IconArrowRight },
  props: {
    gaText: {
      type: String,
      default: "button",
    },
    arrow: {
      type: Boolean,
      default: false,
    },
    size: {
      default: '',
      type: String,
    }
  }
};
</script>

<style lang="scss" scoped>
.button {
  box-sizing: border-box;

  * {
    box-sizing: inherit;
  }

  button {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: 0.03em;
    font-weight: 500;
    border: 3px solid var(--navy);
    border-radius: 6px;

    margin: 0 auto 8px;
    cursor: pointer;
    overflow: hidden;

    // LARGE
    padding: 14px 32px;
  }

  &.primary {
    button {
      background: var(--navy);
      color: white;
    }
  }

  &.secondary {
    button {
      background: var(--pink);
      color: var(--navy);
    }
  }

  &.small {
    button {
      font-size: 16px;
      padding: 14px 24px;
    }
  }

  &.xsmall {
    button {
      font-size: 16px;
      line-height: 1.235;
      padding: 9.5px 16px;
    }
  }

  &.default {
    button {
      background: var(--navy);
    }
  }

  &.no-margin {
    button {
      margin-bottom: 0;
    }
  }

  &.arrow {
    button {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }

  &.disabled {
    button {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  &__arrow {
    width: 11px;
  }
}
</style>
