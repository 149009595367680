<template>
  <div class="Resources">
    <div v-if="isResourcesPage" class="Resources__heading">
      <Header class="clear" />
      <h2>Resources</h2>
    </div>
    <div class="Resources__filter">
      <DynamicResourcesFilter :is-resources-page="isResourcesPage" :first-resource="firstResource"
        :categories="categories" @set-first-resource="setFirstResource" />
    </div>

    <!-- Dynamically created sections -->
    <div v-for="category in categories" :key="category.id" class="Resources__section"
      :id="category.title.replace(' ', '')" :class="firstResource === category.title && 'first'">
      <div class="Resources__intro">
        <h2>{{ category.title }}</h2>
        <p>{{ category.description }}</p>
      </div>

      <!-- RESOURCES -->
      <div class="Resources__cards">
        <component v-for="(resource, index) in sortedResources(category)" :is="resolveComponentName(resource)"
          :key="index" :moduleName="resource.type === 'submitScenarios' ? 'Resources' : undefined
            " v-bind="bindResourceProps(resource)" />
      </div>
    </div>
  </div>
</template>

<script>
import Header from "./components/header";
import DynamicQuizResource from "./components/Resources/DynamicQuizResource";
import DefaultResource from "./components/Resources/DefaultResource";
import DynamicSubmitScenarioResource from "./components/Resources/DynamicSubmitScenarioResource.vue";
import DynamicResourcesFilter from "./components/Resources/DynamicResourcesFilter.vue";

export default {
  name: "DynamicResources",
  components: {
    Header,
    DefaultResource,
    DynamicQuizResource,
    DynamicSubmitScenarioResource,
    DynamicResourcesFilter,
  },
  data: () => ({
    // Add data attributes here
    categories: [],
    firstResource: "Healthy Relationships",
    isResourcesPage: false,
    //All fields used for each Resource type
    allProps: {
      quiz: [
        "question",
        "options",
        "explanations",
        "button",
        "prompts",
        "wrongSticker",
        "correctSticker",
        "order",
        "correctOption",
      ],
      hotTip: ["text", "order"],
      submitScenarios: [
        "question",
        "subtext",
        "placeholder",
        "confidentialMessage",
        "thanksHeading",
        "thanksSubtext",
        "buttonShare",
        "buttonDone",
        "image",
        "color",
        "order",
      ],
      quotes: ["text", "author", "order", "color"],
      emergencyContacts: [
        "name",
        "description",
        "linkText",
        "mainContactMethod",
        "secondaryContactMethod",
        "secondaryDescription",
        "mainContact",
        "link",
        "order",
        "color",
        "contactType",
        "secondaryContact",
      ],
      linkContents: [
        "text",
        "linkText",
        "list",
        "linkURL",
        "order",
        "color",
        "gif",
      ],
      instagram: [
        "hashtags",
        "contentType",
        "contentLink",
        "poster",
        "thumbnail",
        "order",
      ],
      tiktok: ["hashtags", "contentType", "contentLink", "order"],
      youtube: ["description", "contentType", "contentLink", "order"],
      gif: ["description", "contentType", "thumbnail", "order"],
    },
    //All valid field combinations for each Resource type
    requiredPropsMap: {
      quiz: [
        "question",
        "options",
        "explanations",
        "button",
        "prompts",
        "wrongSticker",
        "correctSticker",
        "order",
        "correctOption",
      ],
      hotTip: ["text", "order"],
      submitScenarios: [
        "question",
        "subtext",
        "placeholder",
        "confidentialMessage",
        "thanksHeading",
        "thanksSubtext",
        "buttonShare",
        "buttonDone",
        "image",
        "color",
        "order",
      ],
      quotes: ["text", "author", "order", "color"],
      emergencyContacts: [
        [
          "name",
          "description",
          "linkText",
          "mainContact",
          "link",
          "order",
          "color",
          "contactType",
        ],
        [
          "name",
          "description",
          "linkText",
          "mainContactMethod",
          "secondaryContactMethod",
          "secondaryDescription",
          "mainContact",
          "link",
          "order",
          "color",
          "contactType",
          "secondaryContact",
        ],
        [
          "name",
          "description",
          "linkText",
          "mainContact",
          "link",
          "order",
          "color",
          "contactType",
        ],
        [
          "name",
          "linkText",
          "mainContact",
          "link",
          "order",
          "color",
          "contactType",
        ],
      ],
      linkContents: [
        ["text", "linkText", "linkURL", "order", "color"],
        ["text", "linkText", "list", "linkURL", "order"],
        ["text", "linkText", "gif", "linkURL", "order"],
      ],
      instagram: [
        ["hashtags", "contentType", "contentLink", "poster", "order"],
        ["contentType", "contentLink", "thumbnail", "order"],
      ],
      tiktok: [["hashtags", "contentType", "contentLink", "order"]],
      youtube: [["description", "contentType", "contentLink", "order"]],
      gif: [["description", "contentType", "thumbnail", "order"]],
    },
  }),
  computed: {
    console: () => console,
    window: () => window,
  },
  async created() {
    const locale = this.$i18n.locale;
    console.log("resources locale", locale);


    const content = await this.$cms.getResource(
      "resource-categories?_sort=order:ASC&_limit=-1&_locale=" + locale
    );

    // Filter the data to only include entries with locale set to the selected locale
    this.categories = content.filter((category) => category.locale === locale);
    //this.categories = response.data;
    this.firstResource = this.categories[0].title;
  },
  methods: {
    setFirstResource: function (id) {
      this.firstResource = id;
    },
    sortedResources(category) {
      // Combine all resources into one array
      const allResources = [
        ...category.quizzes.map((resource) => ({
          ...resource,
          type: "quiz",
          category: category.title.replace(" ", ""),
        })),
        ...category.hotTips.map((resource) => ({
          ...resource,
          type: "hotTip",
          category: category.title.replace(" ", ""),
        })),
        ...category.submitScenarios.map((resource) => ({
          ...resource,
          type: "submitScenarios",
          category: category.title.replace(" ", ""),
        })),
        ...category.quotes.map((resource) => ({
          ...resource,
          type: "quotes",
          category: category.title.replace(" ", ""),
        })),
        ...category.emergencyContacts.map((resource) => ({
          ...resource,
          type: "emergencyContacts",
          category: category.title.replace(" ", ""),
        })),
        ...category.linkContents.map((resource) => ({
          ...resource,
          type: "linkContents",
          category: category.title.replace(" ", ""),
        })),
        ...category.embeddedContents.map((resource) => ({
          ...resource,
          type: resource.contentType,
          category: category.title.replace(" ", ""),
        })),
      ];
      // Filter out any resources that are empty
      const nonEmptyResources = allResources.filter(
        (resource) => Object.keys(resource).length > 0
      );

      const validResources = nonEmptyResources.filter((resource) =>
        this.isValidResource(resource)
      );

      // Sort resources based on their order field
      const sortedResources = validResources.sort(
        (a, b) => parseInt(a.order) - parseInt(b.order)
      );

      return sortedResources;
    },

    isValidResource(resource) {
      const resourceType = resource.type;

      if (
        !Object.prototype.hasOwnProperty.call(
          this.requiredPropsMap,
          resourceType
        )
      ) {
        console.error(`Unrecognized resource type "${resourceType}".`);
        return false;
      }

      if (!Object.prototype.hasOwnProperty.call(this.allProps, resourceType)) {
        console.error(
          `Resource type "${resourceType}" is not defined in allProps.`
        );
        return false;
      }

      const requiredProps = this.requiredPropsMap[resourceType];
      const allProps = this.allProps[resourceType];

      if (this.hasExtraProperties(resource, allProps)) {
        console.error(
          `Resource of type "${resourceType}" has extra properties.`
        );
        return false;
      }

      if (!this.hasRequiredProps(resource, requiredProps)) {
        console.error(
          `Resource of type "${resourceType}" is missing required properties.`
        );
        return false;
      }

      return true;
    },

    hasAllProperties(resource, properties) {
      const resourceProps = Object.keys(resource);
      return properties.every(
        (prop) => resourceProps.includes(prop) && resource[prop] !== null
      );
    },

    hasExtraProperties(resource, allProperties) {
      const resourceProps = Object.keys(resource).filter(
        (key) => resource[key] !== null
      );
      const requiredProps = this.requiredPropsMap[resource.type];
      let isValid = false;

      if (Array.isArray(requiredProps[0])) {
        for (const subProps of requiredProps) {
          const isSubset = resourceProps.every(
            (prop) => subProps.includes(prop) || !allProperties.includes(prop)
          );
          const hasUnwantedExtra = resourceProps.some(
            (prop) => allProperties.includes(prop) && !subProps.includes(prop)
          );
          if (isSubset && !hasUnwantedExtra) {
            isValid = true;
            break;
          }
        }
      } else {
        const isSubset = resourceProps.every(
          (prop) =>
            requiredProps.includes(prop) || !allProperties.includes(prop)
        );
        const hasUnwantedExtra = resourceProps.some(
          (prop) =>
            allProperties.includes(prop) && !requiredProps.includes(prop)
        );
        isValid = isSubset && !hasUnwantedExtra;
      }

      return !isValid;
    },

    hasRequiredProps(resource, requiredProps) {
      if (Array.isArray(requiredProps[0])) {
        return requiredProps.some((subProps) =>
          this.hasAllProperties(resource, subProps)
        );
      } else {
        return this.hasAllProperties(resource, requiredProps);
      }
    },

    bindResourceProps(resource) {
      if (resource.type === "quiz") {
        // Transform options to desired choices format
        let choices = [];
        for (let option = 0; option < resource.options.length; option++) {
          choices.push({
            text: resource.options[option].replaceAll("\\", ""),
            isCorrect: option + 1 === resource.correctOption,
            tag: "option_" + (option + 1),
          });
        }

        return {
          prompt: resource.question,
          choices: choices,
          explanation: resource.explanations.explanations,
          resource: resource,
        };
      } else {
        return {
          resource: resource,
        };
      }
    },
    goBackLink() {
      this.$gtm.trackEvent({
        event: "nav_click",
        module_name: "Resources",
        nav_type: "go back",
        link_url: "/endgame",
      });
      this.$router.push({ path: "/endgame" });
    },
    goToLink(url, cat, linkText) {
      this.$gtm.trackEvent({
        event: "resource_link_click",
        module_name: cat,
        link_url: url,
        click_text: linkText,
        category: cat,
      });
      window.open(url);
    },
    goToPhone(num, cat) {
      this.$gtm.trackEvent({
        event: "resource_link_click",
        module_name: cat,
        link_url: "tel:" + num,
        click_text: num,
      });
      window.open("tel:" + num);
    },
    resolveComponentName(resource) {
      if (resource.type === "quiz") return "DynamicQuizResource";
      else if (resource.type === "submitScenarios")
        return "DynamicSubmitScenarioResource";
      return "DefaultResource";
    },
  },
  mounted() {
    // Any actions to do on component mount
    if (
      this.$route.fullPath === "/dynamic-resources" ||
      this.$route.fullPath === "/resources"
    ) {
      this.isResourcesPage = true;

      this.$gtm.trackEvent({
        event: "virtual_page_view",
        page_name: "resources page",
        page_url: window.location.href,
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.Resources {
  display: flex;
  flex-direction: column;
  background-color: var(--light-cream);

  &__heading {
    order: -3;

    h2 {
      text-align: center;
      color: var(--navy);
      font-family: "cheee";
      font-size: 40px;
      font-weight: 520;
      margin-bottom: 1.75rem;
      margin-top: 20px;

      @media only screen and (min-width: 701px) {
        font-size: 58px;
        margin-bottom: 2.5rem;
        margin-top: 2.5rem;
      }
    }
  }

  &__filter {
    order: -2;
  }

  .first {
    order: -1;
    padding-top: 20px;
  }

  &__section {
    padding: 0px var(--spacing) 47px;
    min-height: 100vh;
  }

  &__intro {
    position: relative;
    text-align: center;
    margin-bottom: var(--spacing-medium);
    font-size: 14px;
    line-height: 1.4;

    @media only screen and (min-width: 701px) {
      max-width: 900px;
      margin-left: auto;
      margin-right: auto;
      font-size: 22px;
    }

    h2 {
      color: var(--navy);
      font-size: 24px;
      font-weight: 750;
      line-height: 1.3;
      width: 100%;
      margin-bottom: var(--spacing-small);
      padding: 0 24px;

      @media only screen and (min-width: 701px) {
        max-width: 650px;
        margin-left: auto;
        margin-right: auto;
      }
    }

    p {
      color: var(--navy);
      font-weight: 500;
      width: 100%;
      font-size: 14px;
      line-height: 1.4;
      max-width: 280px;
      margin: 0 auto;

      @media only screen and (min-width: 701px) {
        max-width: 650px;
      }
    }
  }

  //&__intro

  &__formatted {
    position: relative;

    a {
      cursor: pointer;
      text-decoration: underline;
    }

    a.no-underline {
      text-decoration: none;
    }

    h1 {
      font-size: 22px;
      line-height: 1.4;
      margin-bottom: 1em;
    }

    h2 {
      font-size: 18px;
      margin-bottom: 1em;
    }

    p {
      margin-bottom: 1em;
      font-weight: 400;
    }

    ul {
      font-size: 14px;
      font-weight: 400;
      text-align: left;
      margin-bottom: var(--spacing-medium);

      li {
        position: relative;
        margin: 0 0 1.4em 40px;

        &::before {
          position: absolute;
          content: "";
          width: 20px;
          height: 20px;
          border: 2px solid var(--navy);
          border-radius: 8px;
          left: -40px;
          top: 6px;
        }
      }
    }
  }

  &__back {
    position: absolute;
    top: 10px;
    left: 0;
    width: 18px;
    cursor: pointer;
  }

  &__cards {
    width: 100%;
    max-width: 450px;
    margin: 0 auto;

    >* {
      // This selector will select all direct children of .Resources__cards
      margin-bottom: var(--spacing-medium);
    }

    @media only screen and (min-width: 701px) {
      max-width: 650px;
      columns: 2;
      column-gap: var(--spacing-medium);
    }
  }
}
</style>
